

import React, {  }          from 'react';
import { useTranslation }   from "react-i18next";

import { goToSite }     from "../../utils/misc";

const GoToSite = ({isMobile, classNameContainer}) => {

    const {t, i18n} = useTranslation();
    const lang = i18n.language || 'en';

    return (
        <div onClick={() => goToSite(lang)}
             className={`d-flex align-items-center ${classNameContainer}`}
             style={{
                height: '24px',
             }}
        >
            <div className={`d-flex align-items-center ${isMobile ? '' : 'flex-column'} cursor-pointer`}
                 style={{
                    textAlign: 'center'
                 }}
            >
                <img className={''} src={`/assets/img/globe.png`} alt="new" height={isMobile ? '25px' : '31px'}/>
                <span className={`my-text-primary ${isMobile ? 'ml-1' : 'mt-1'} fs-13px`}>{t('New_website')}</span>
            </div>
        </div>
    );
};

export default GoToSite;
