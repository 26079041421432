import React, { memo, useMemo, useEffect }              from 'react';
import { useTranslation }           from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MenuSmall                    from "./MenuSmall";
import MenuLarge                    from "./MenuLarge";
import { routesNames }              from "../../constants";
import { setShowMenu, SET_KEY }              from "../../storage/global";
import {Button} from "react-bootstrap";
import ButtonSmallNoBorder from "../shared/ButtonSmallNoBorder";
import CopyIcon from "../shared/CopyIcon";
import {copy, viewAlert} from "../../utils/misc";
import { makeGetRequest } from "../../utils/api.js";

const Menu = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentLocation = useLocation().pathname;
    const isMobile = useSelector(state => state.global.isMobile);
    const profile = useSelector(state => state.global.profile)
    const lostList = useSelector(state => state.global.lost.list)
    const lang = useSelector(state => state.global.locale)
    const qrCode = useSelector(state => state.global.qrCode)

    useEffect(() => {
        if(profile?.usercode) {
            makeGetRequest('/api/misc/getUserQRCodes', {usercode: profile?.usercode}).then((res) => {
                if(res.data && res.data.length) {
                    dispatch({type: SET_KEY, payload: {
                        key: 'qrCode',
                        value: res.data[0]
                    }})
                }
            })
            .catch((err) => {
                console.log("🚀 getUserQRCodes err:", err);
                viewAlert(dispatch, err)
            })
        }
    }, [profile?.usercode])

    const lightTheme = !isMobile;

    const menuList = useMemo(() => {

        const unidentifiedList = lostList.length ? [{
            active: `/${routesNames.UNIDENTIFIED_PARCELS}` === currentLocation,
            title: t('unidentified_parcels'),
            ico: {
                src: `/assets/img/${lightTheme ? 'question.svg' : 'question-blue.svg'}`,
                alt: 'question'
            },
            handle: () => goTo(routesNames.UNIDENTIFIED_PARCELS),
        }] : [];

        const navList = [
            {
                active: `/${routesNames.NEW_PARCELS}` === currentLocation,
                title: t('new_parcels'),
                ico: {
                    src: `/assets/img/new-parcel-${lightTheme ? 'light' : 'dark'}.svg`,
                    alt: 'shopping'
                },
                handle: () => goTo(routesNames.NEW_PARCELS),
            },
            {
                active: `/${routesNames.WAITING_ARRIVAL}` === currentLocation,
                title: t('waiting_arrival'),
                ico: {
                    src: `/assets/img/archive-tray-${lightTheme ? 'light' : 'dark'}.svg`,
                    alt: 'archive'
                },
                handle: () => goTo(routesNames.WAITING_ARRIVAL),
            },
            {
                active: `/${routesNames.SENT_PARCELS}` === currentLocation,
                title: t('sent_parcels'),
                ico: {
                    src: `/assets/img/archive-trayout-${lightTheme ? 'light' : 'dark'}.svg`,
                    alt: 'unarchive'
                },
                handle: () => goTo(routesNames.SENT_PARCELS),
            },
            ...unidentifiedList,
            {
                active: `/${routesNames.PROMO_CODES}` === currentLocation,
                title: t('promo_codes'),
                ico: {
                    src: `/assets/img/icon-promo${lightTheme ? '' : '-blue'}.svg`,
                    alt: 'promo'
                },
                handle: () => goTo(routesNames.PROMO_CODES),
            },
            {
                active: `/${routesNames.ACCOUNTING}` === currentLocation,
                title: t('accounting'),
                ico: {
                    src: `/assets/img/money-${lightTheme ? 'light' : 'dark'}.svg`,
                    alt: 'money'
                },
                handle: () => goTo(routesNames.ACCOUNTING),
            },
            {
                admin: true,
                active: `/${routesNames.ADMIN}` === currentLocation,
                title: t('admin'),
                ico: {
                    src: `/assets/img/profile-${lightTheme ? 'light' : 'dark'}.svg`,
                    alt: 'admin'
                },
                handle: () => goTo(routesNames.SYSTEM_SETTINGS),
            },
            {
                admin: true,
                active: `/${routesNames.DOCS}` === currentLocation,
                title: t('docs'),
                ico: {
                    src: `/assets/img/profile-${lightTheme ? 'light' : 'dark'}.svg`,
                    alt: 'documentation'
                },
                handle: () => goTo(routesNames.DOCS),
            },
            {
                active: `/${routesNames.TERMS_AND_CONDITIONS}` === currentLocation,
                title: t('terms_and_conditions'),
                className: 'd-flex',
                ico: {
                    src: `/assets/img/${lightTheme ? 'question.svg' : 'question-blue.svg'}`,
                    alt: 'money'
                },
                handle: () => {
                    const a = document.createElement('a')
                    a.target = '_blank';
                    const linkFile = lang === 'en' ? 'public_offer_en.pdf' : 'public_offer_zh.pdf';
                    a.href = `/assets/files/${linkFile}`;
                    a.click();
                },
                styles: { marginTop: '34px' },
                spanStyles: { fontSize: '12px' }
            },
            // {
            //     admin: false,
            //     active: false,
            //     title: t('wh_address') + profile?.usercode,
            //     className: '',
            //     afterChildren: <div>
            //         <Button variant={'outline-primary'}>
            //             <span>{t('wh_copy')}</span>
            //         </Button>
            //         {/*<ButtonSmallNoBorder classNames={'btn-wh-copy'} variant={'outline-white-no-border'}>*/}
            //
            //         {/*</ButtonSmallNoBorder>*/}
            //     </div>,
            //     handle: () => {
            //         copy(t('wh_address') + profile?.usercode)
            //     },
            //     styles: {
            //         position: isMobile ? 'unset' : 'fixed',
            //         bottom: '65px',
            //         width: '220px',
            //         fontSize: '12px',
            //         fontWeight: '400'
            //     }
            // },
        ]
        return navList;
    }, [lostList, currentLocation])

    function goTo(path) {
        navigate(`/${path}`)
        dispatch(setShowMenu(false))
    }

    return (
        <>
            <MenuLarge navList={menuList} isMobile={isMobile} qr_code={qrCode?.qr_code}/>
            <MenuSmall navList={menuList}/>
        </>
    );
};

export default memo(Menu);
