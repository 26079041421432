import React, {useEffect, useState, useMemo, useRef} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import moment from "moment";

import localStyles from './RegisterForm.module.scss'

import {
    CAPTCHA_SWITCH,
    DEFAULT_ERROR_OBJECT,
    EMAIL,
    INPUT_CAPTCHA,
    MANAGER,
    BIRTHDAY,
    COMPANY_NAME,
    NAME,
    PASSWORD, PASSWORD_AGAIN,
    PASSWORD_CONFIRM,
    DOCUMENTS_IMAGES,
    PHONE,
    routesNames, PHONE_VERIFY
} from "../../constants";
import {
    emailValidate,
    emailSpecialCharactersValidate,
    noEmptyValidate, matchValidate,
    isCorrectOption,
    numberValidate,
    letterNumberValidate,
    withLastLetter,
    letterSpaceHyphenValidate3,
    letterNumberSpaceHyphenValidate,
    noEmptyArrayValidate,
    passwordValidate,
    validateLength, customValidate
} from "../../utils/validate";
import { getCookie }        from "../../utils/cookie";
import {checkValidate, serializeFormToObject, viewAlert, debounce} from "../../utils/misc";
import {getManagerList, signup, checkPhoneNumExistKK, checkEmailExistKK, makeGetRequest, makePostRequest } from "../../utils/api";
import InputText from "../input/InputText";
import InputName from "../input/InputName";
import InputPhone from "../input/InputPhone";
import InputIdCardNumber from "../input/InputIDCardNumber";
import InputEmail from "../input/InputEmail";
import InputPassword from "../input/InputPassword";
import InputFile from "../input/InputFile";
import Select from "../input/Select";
import Switch from "../input/Switch";
import {setLoadSpinner, setAlertShow} from "../../storage/global";
import Loader from "../Loader";
import Spinner from "../shared/Spinner";
import PhoneVerify from "./PhoneVerify";

const ford_id = 'registration-form';

const RegisterForm = () => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentLocation = useLocation();

    const defManager = {GUID: '', FullName: t('selectManager'), email: ""};
    const size_5_MB = t('The_file_size_cannot_exceed_5_MB');
    const formatDate = 'yyyy.MM.dd';

    const [errorCompany, setErrorCompany] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorName, setErrorName] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPhone, setErrorPhone] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorEmail, setErrorEmail] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPassword, setErrorPassword] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPasswordAgain, setErrorPasswordAgain] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorCaptcha, setErrorCaptcha] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorManager, setErrorManager] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorBirthday, setErrorBirthday] = useState({...DEFAULT_ERROR_OBJECT});

    const [pass, setPass] = useState({
        first: '',
        second: '',
    })

    const [emailDuplicate, setEmailDuplicate] = useState('')
    const [manager, setManager] = useState(defManager)
    const [managerList, setManagerList] = useState([defManager])
    const [birthday, setBirthday] = useState('')
    const [loadManagerList, setLoadManagerList] = useState(true)
    const [robot, setRobot] = useState(false)
    const [trySubmit, setTrySubmit] = useState(false)
    const [phone, setPhone] = useState('')
    const [phoneIsCheckNumExistKK, setPhoneIsCheckNumExistKK] = useState(false)
    const [emailIsCheckExistKK, setEmailIsCheckExistKK] = useState(false)
    const [phoneIsVerify, setPhoneIsVerify] = useState(false)
    const [emailUnique, setEmailUnique] = useState(false)
    const [connectionId, setConnectionId] = useState('')
    const [verifyCode, setVerifyCode] = useState('')
    const [sendAnaliticsStartInput, setSendAnaliticsStartInput] = useState(false)
    const [forbiddenCompanyName, setForbiddenCompanyName] = useState([])

    const [tests, setTests] = useState([]);
    const [withABTest, setWithABTest] = useState(false);
    const [abIdReg, setAbIdReg] = useState(0);

    const formRef = useRef(null);
    const [formData, setFormData] = useState({});
    const formDataRef = useRef(formData);
    formDataRef.current = formData
    const robotRef = useRef(robot);
    robotRef.current = robot
    const phoneIsVerifyRef = useRef(phoneIsVerify);
    phoneIsVerifyRef.current = phoneIsVerify
    const withABTestRef = useRef(withABTest);
    withABTestRef.current = withABTest
    const abIdRegRef = useRef(abIdReg);
    abIdRegRef.current = abIdReg

    const checkPhoneExistKK = useMemo(() => {
        const [debouncedFunc] = debounce(checkPhoneNumExistKK, 700);
        return debouncedFunc;
    }, [])

    const birthdayMin = useMemo(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const birthdayMin_ = new Date(yyyy - 15, today.getMonth(), today.getDate())
        return birthdayMin_;
    }, [])

    const code_inviting_client = useMemo(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const cic = queryParams.get('code_inviting_client');
        return cic;
    }, [currentLocation]);

    useEffect(() => {
        if(code_inviting_client) {
            const params = {
                UserId: code_inviting_client,
            };
            makePostRequest("/api/misc/RecordReferalLinkClick ", params).then((res) => {
                // console.log("🚀 RecordReferalLinkClick res:", res);
            })
            .catch((err) => {
                console.log('RecordReferalLinkClick catch: ', err);
            })
        }
    }, [code_inviting_client]);

    const checkEmailExist = useMemo(() => {
        const [debouncedFunc] = debounce(checkEmailExistKK, 700);
        return debouncedFunc;
    }, [])

    const validations = {}
    validations[COMPANY_NAME] = [noEmptyValidate(t('validEmpty')),
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterNumberSpaceHyphenValidate(t('IncorrectYourName'))
    ]
    validations[NAME] = [noEmptyValidate(t('validEmpty')),
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterSpaceHyphenValidate3(t('IncorrectYourName'))
    ]
    validations[PHONE] = [noEmptyValidate(t('validEmpty')), numberValidate(t('validNumber')), validateLength(5, 0, t('IncorrectPhoneNumber'))]
    validations[EMAIL] = [noEmptyValidate(t('validEmpty')), emailValidate(t('validEmail')), emailSpecialCharactersValidate(t('validEmail'))]
    validations[PASSWORD] = [noEmptyValidate(t('validEmpty')), passwordValidate(t('validPassword'))]
    validations[PASSWORD_CONFIRM] = [noEmptyValidate(t('validEmpty')), passwordValidate(t('validPassword'))]
    validations[INPUT_CAPTCHA] = [noEmptyValidate(t('validEmpty'))]
    validations[MANAGER] = [noEmptyValidate(t('validEmpty')), isCorrectOption(t('validEmpty'), 'GUID')]
    validations['MANAGER_GUID'] = [noEmptyValidate(t('validEmpty'))]
    validations['BIRTHDAY'] = []

    useEffect(() => {
        getManagerList()
            .then((response) => {
                setManagerList([defManager].concat(response.data))
                setLoadManagerList(false)
            })
            .catch(({response}) => {
                viewAlert(dispatch, response)
            })

        makeGetRequest('/api/misc/getForbiddenCommodity', {
                checking_type: "company_name"
            }
        ).then(({data}) => {
            data && setForbiddenCompanyName(data.response || []);
        })
        .catch(({response}) => {
            console.log("🚀 getForbiddenCommodity catch:", response);
            viewAlert(dispatch, response)
        })

        let withABTest_ = false;
        makeGetRequest('/api/misc/getABTests', {}).then((res) => {
            if(res.data && res.data.length) {
                const testForShow = res.data.map( t => ({
                    id: t.id,
                    name: t.name,
                    active: t.active,
                    date_from: new Date(t.date_from),
                    date_to: new Date(t.date_to),
                }));
                const testReg = testForShow.find(test => test.id === 'ab_registration_form');
                setTests(testForShow)
                if(testReg.active && testReg.date_from <= (new Date()) && testReg.date_to >= (new Date())) {
                    setWithABTest(true)
                    withABTest_ = true;
                }
            } else {
                setWithABTest(false)
            }
        })
        .catch((err) => {
            console.log("🚀 getABTests err:", err);
            viewAlert(dispatch, err)
        })
        .finally(() => {
            const ab_id_reg = getCookie('ab_id_reg');
            setAbIdReg(ab_id_reg);
            makeGetRequest("/api/misc/kk_analytics", {
                action: 'registration_form_open',
                a_data: !withABTest_ ? '{}' : JSON.stringify({
                    ab_id_reg
                })
            });
        });
        window.addEventListener("beforeunload", onBeforeUnload);
        return (preFormData_) => {
            window.removeEventListener('beforeunload', onBeforeUnload);
            saveCheckingFormData(formDataRef.current);
        }
    }, [])

    function onBeforeUnload(ev) {
        ev.preventDefault();
        saveCheckingFormData(formDataRef.current);
        window.alert(t('Want_to_leave_page'));
        return;
    }

    function saveCheckingFormData(formData_) {
        if (formData_) {
            const data = formData_;
    
            data[PHONE] = data[PHONE]?.toString()?.replaceAll(/[ -]*/ig, '') || '';
            data.connection_id = connectionId;
            data.code = verifyCode;
            data.managerEmail = "";
            managerList.forEach(mng => {
                if(manager.GUID && mng.GUID === manager.GUID ) {
                    data.managerEmail = mng.email;
                }
            });

            const keysFields = [
                COMPANY_NAME,
                NAME,
                PHONE,
                EMAIL,
                PASSWORD,
                PASSWORD_CONFIRM,
                INPUT_CAPTCHA,
                MANAGER,
            ];
            const errors = [];
            keysFields.forEach( (key) => {
                if(data[key]) errors.push({field: key, ...checkValidate(data[key], validations[key]),});
            })
            errors.push({field: CAPTCHA_SWITCH, isError: !robotRef.current, message: !robotRef.current ? 'not set' : ''});

            const statFormData = {
                errors,
                phoneIsVerify: phoneIsVerifyRef.current,
                withABTest: withABTestRef.current,
                abIdReg: abIdRegRef.current,
            }
            makeGetRequest("/api/misc/kk_analytics", {
                action: 'registration_form_close',
                a_data: JSON.stringify(statFormData)
            });
        }
    }

    function checkForbiddenCompanyName(value: string, setError): boolean {
        return !!forbiddenCompanyName
            .map(fc => {

                const reg = new RegExp(`${!fc.SearchByEntry ? '^' : ''}(${fc.Text})${!fc.SearchByEntry ? '$' : ''}`, 'ig')

                const isError = reg.test(value.trim());
                if (isError) {
                    setError({
                        isError: true,
                        message: t('validValueForbidden')
                    })
                }
                return isError;

            })
            .find(v => v)
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        setTrySubmit(true);

        const form = e.currentTarget;
        const data = serializeFormToObject(form)

        data[PHONE] = data[PHONE].toString().replaceAll(/[ -]*/ig, '')
        data.connection_id = connectionId;
        data.code = verifyCode;
        data.managerEmail = "";
        data.lng = i18n.language;
        if(birthday) {
            const d = new Date(birthday);
            const m = moment(d);
            const birthday_ = m.format();
            data.birthday = birthday_;
        } else {
            data.birthday = '';
        }
        managerList.forEach(mng => {
            if(manager.GUID && mng.GUID === manager.GUID ) {
                data.managerEmail = mng.email;
            }
        });

        const errors = [
            checkValidate(data[COMPANY_NAME], validations[COMPANY_NAME], setErrorCompany),
            checkValidate(data[NAME], validations[NAME], setErrorName),
            checkValidate(data[PHONE], validations[PHONE], setErrorPhone),
            checkValidate(data[EMAIL], validations[EMAIL], setErrorEmail),
            checkValidate(data[PASSWORD], validations[PASSWORD], setErrorPassword),
            checkValidate(data[PASSWORD_CONFIRM], validations[PASSWORD_CONFIRM].concat([matchValidate(data[PASSWORD], t('passwordMatch'))]), setErrorPasswordAgain),
            checkValidate(data[INPUT_CAPTCHA], validations[INPUT_CAPTCHA], setErrorCaptcha),
            checkValidate(data[MANAGER], validations['MANAGER_GUID'], setErrorManager),
            checkValidate(data[BIRTHDAY], validations['BIRTHDAY'], setErrorBirthday),
        ]

        const isError = errors.filter(er => er.isError).length
        const its_dev_registration = process.env.REACT_APP_DEV_REGISTRATION;
        if (!isError
            && robot
            && (((phoneIsVerify || its_dev_registration) && !withABTest)
                || (phoneIsVerify && withABTest && abIdReg && abIdReg % 2 != 0)
                || (withABTest && abIdReg && abIdReg % 2 == 0)
            )
            && emailUnique
        ) {

            dispatch(setLoadSpinner(true))

            signup(data)
                .then(async (response) => {
                    const { useruid, ID } = response.data || {};
                    await makeGetRequest("/api/misc/kk_analytics", {
                        action: 'registration_form_signup',
                        a_data: !withABTest ? '{}' : JSON.stringify({
                            ab_id_reg: abIdReg,
                            user_code: ID
                        })
                    });
                    if(code_inviting_client) {
                        const params = {
                            UserId: code_inviting_client,
                            RefUserId: ID
                        };
                        console.log("🚀 referalPromo params:", params);
                        makePostRequest("/api/misc/referalPromo", params).then((res) => {
                            console.log("🚀 referalPromo res:", res);
                            const promocodeNum = res?.data?.response.promocodeNum || '';
                            makeGetRequest('/api/misc/createUserQRCodes', {usercode: ID}).catch((err) => {
                                console.log("🚀 createUserQRCodes err:", err);
                            })
                        })
                        .catch((err) => {
                            console.log('referalPromo catch: ', err);
                        })
                        .finally(() => {
                            viewAlert(dispatch, response)
                            setTimeout(() => {
                                navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                                viewAlert(dispatch, response, {show: false})
                            }, 2000)
                        });
                    } else {
                        viewAlert(dispatch, response)
                        setTimeout(() => {
                            navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                            viewAlert(dispatch, response, {show: false})
                        }, 2000)
                    }
                })
                .catch(({response}) => {
                    console.log("🚀 signup err res:", response);
                    viewAlert(dispatch, response)
                })
                .finally(() => dispatch(setLoadSpinner(false)))
        }
    }

    async function handleValues(value, name, inputRef) {
        switch (name) {
            case COMPANY_NAME: {
                checkValidate(value, validations[name], setErrorCompany)
                checkForbiddenCompanyName(value, setErrorCompany);
                break;
            }
            case NAME: {
                checkValidate(value, validations[name], setErrorName)
                checkForbiddenCompanyName(value, setErrorName);
                break;
            }
            case PHONE: {
                const validError = checkValidate(value, validations[name], setErrorPhone)
                setPhone(value)
                setPhoneIsVerify(false)
                if(value && !validError.isError) {
                    setPhoneIsCheckNumExistKK(false)
                    setErrorPhone({
                        isError: true,
                        message: t('phone_checking')
                    })
                    checkPhoneExistKK({phone: value}).then((response) => {
                        if(response.exist) {
                            setErrorPhone({
                                isError: true,
                                message: t('phone_already_registered')
                            })
                        } else if(response.status) {
                            setErrorPhone({
                                isError: true,
                                message: 'Error, status: ' + response.status
                            })
                        } else if(!response.exist) {
                            setErrorPhone({
                                isError: false,
                                message: ''
                            })
                        }
                        setPhoneIsCheckNumExistKK(true);
                    }).catch((err) => {
                        console.log("🚀  checkPhoneExistKK  err:", err);
                        setErrorPhone({
                            isError: true,
                            message: err.status ? 'Error, status: ' + err.status : err.toString()
                        })
                    })
                }
                break;
            }
            case EMAIL: {
                setEmailDuplicate(value);
                const validError = checkValidate(value, validations[name], setErrorEmail);
                if(value && !validError.isError) {
                    setEmailIsCheckExistKK(false)
                    setErrorEmail({
                        isError: true,
                        message: t('email_checking')
                    })
                    checkEmailExist({email: value}).then((response) => {
                        if(response.exist) {
                            setEmailUnique(false);
                            setErrorEmail({
                                isError: true,
                                message: t('email_already_registered')
                            })
                        } else if(response.status) {
                            setEmailUnique(false);
                            setErrorEmail({
                                isError: true,
                                message: 'Error, status: ' + response.status
                            })
                        } else if(!response.exist) {
                            setEmailUnique(true);
                            // повторюємо перевірку бо за час запиту значення можна змінити
                            checkValidate(emailDuplicate, validations[EMAIL], setErrorEmail);
                            // setErrorEmail({
                            //     isError: false,
                            //     message: ''
                            // })
                        }
                        setEmailIsCheckExistKK(true);
                    }).catch((err) => {
                        console.log("🚀  checkEmailExist  err:", err);
                        setEmailUnique(false);
                        setErrorEmail({
                            isError: true,
                            message: err.status ? 'Error, status: ' + err.status : err.toString()
                        })
                    })
                }
                break;
            }
            case PASSWORD:
                setPass({...pass, first: value})
                checkValidate(value, validations[name], setErrorPassword)
                break;
            case PASSWORD_CONFIRM:
                setPass({...pass, second: value})
                checkValidate(value, validations[name].concat([matchValidate(pass.first, t('passwordMatch'))]), setErrorPasswordAgain)
                break;
            case INPUT_CAPTCHA:
                checkValidate(value, validations[name], setErrorCaptcha)
                break;
            case MANAGER: {
                setManager(value);
                checkValidate(value, validations[name], setErrorManager)
                break;
            }
            case BIRTHDAY: {
                setBirthday(value);
                checkValidate(value, validations[name], setErrorBirthday)
                break;
            }
            case CAPTCHA_SWITCH:
                setRobot(value);
                break;
            default:
                break;
        }
        if(name !== EMAIL && name !== PASSWORD && !sendAnaliticsStartInput) {
            makeGetRequest("/api/misc/kk_analytics", {
                action: 'registration_form_started_filling_up',
                a_data: !withABTest ? '{}' : JSON.stringify({
                    ab_id_reg: abIdReg
                })
            });
            setSendAnaliticsStartInput(true);
        }
        const form = formRef.current;
        const data = serializeFormToObject(form)
        setFormData(data);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                const base64result = fileReader.result.substr(fileReader.result.indexOf(',') + 1);
                resolve(base64result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    function onCodeCheck(isVerify, connectionId, verifyCode) {
        setPhoneIsVerify(isVerify);
        setConnectionId(connectionId);
        setVerifyCode(verifyCode);
    }

    const verification_code = withABTest && abIdReg && abIdReg % 2 == 0 ? [] : [{
        el: PhoneVerify,
        id: PHONE_VERIFY,
        classes: 'mt-4',
        placeholder: t('verification_code'),
        errorMessage: errorPhone.isError,
        handleChange: onCodeCheck,
        validations: [],
        closeButton: true,
        required: true,
        value: phone,
    }];

    const inputList = [
        {
            el: InputText,
            id: COMPANY_NAME,
            classes: '',
            placeholder: t('your_company'),
            errorMessage: errorCompany.message,
            handleChange: handleValues,
            validations: validations[COMPANY_NAME],
            closeButton: true,
        },
        {
            el: InputName,
            id: NAME,
            classes: 'mt-4',
            placeholder: t('your_name'),
            errorMessage: errorName.message,
            handleChange: handleValues,
            validations: validations[NAME],
            closeButton: true,
        },
        {
            el: InputPhone,
            id: PHONE,
            classes: 'mt-4',
            placeholder: t('phone_number'),
            errorMessage: errorPhone.message,
            handleChange: handleValues,
            validations: validations[PHONE],
            closeButton: true,
        },
        ...verification_code,
        {
            el: InputEmail,
            id: EMAIL,
            classes: 'mt-4',
            placeholder: t(EMAIL),
            errorMessage: errorEmail.message,
            handleChange: handleValues,
            validations: validations[EMAIL],
            closeButton: true,
        },
        {
            el: InputPassword,
            id: PASSWORD,
            classes: 'mt-4',
            placeholder: t(PASSWORD),
            errorMessage: errorPassword.message,
            handleChange: handleValues,
            validations: validations[PASSWORD],
            closeButton: true,
            value: pass.first,
        },
        {
            el: InputPassword,
            id: PASSWORD_CONFIRM,
            classes: 'mt-4',
            placeholder: t(PASSWORD_AGAIN),
            errorMessage: errorPasswordAgain.message,
            handleChange: handleValues,
            validations: validations[PASSWORD_CONFIRM],
            closeButton: true,
            value: pass.second,
        },
    ]

    function selectLabelText() {
        return <div className="row h-100">
            <div className="col" style={{lineHeight: '42px'}}>{t('Upload_documents')}</div>
            <i
                className="fa fa-question col-auto w-0 m-p-0 right-icon cursor-def"
                // title={t('Upload_documents_title')}
                onClick={e => {
                    e.stopPropagation();
                }}
                data-tip={t('Upload_documents_title')}
                style={{
                    width: '40px',
                    height: '40px',
                    padding: '7px',
                }}
            >
            </i>
            {/* delayHide={2500} */}
            <ReactTooltip effect={'solid'} className={'my-tooltip'} delayShow={250}/>
        </div>
    }

    return (
        <Form onSubmit={handleSubmit} id={ford_id} ref={formRef}>
            {
                inputList.map((item, key) => {
                    return (
                        <item.el
                            key={key}
                            id={item.id}
                            classes={item.classes}
                            placeholder={item.placeholder}
                            errorMessage={item.errorMessage}
                            handleChange={item.handleChange}
                            handleBlur={item.handleChange}
                            validations={item.validations}
                            closeButton={item.closeButton}
                            value={item.value}
                            customMask={item.mask}
                            required={item.required}
                            isCheckNumExistKK={item.isCheckNumExistKK}
                        />
                    )
                })
            }
            <Select
                id={'manager'}
                className={'mt-4 text-center'}
                options={managerList}
                selectValue={manager}
                errorMessage={errorManager.message}
                loaded={loadManagerList}
                keyName={'GUID'}
                nameValue={'FullName'}
                handleChange={handleValues}
            />
            <div className={`mt-4`}>
                <DatePicker
                    id={BIRTHDAY}
                    maxDate={birthdayMin}
                    selected={birthday}
                    onChange={(date: Date) => handleValues(date, BIRTHDAY)}
                    dateFormat={formatDate}
                    placeholderText={t('Date_of_birth')}
                    showYearDropdown
                    yearDropdownItemNumber={20}
                    // showMonthYearDropdown
                />
            </div>
            <Form.Group controlId={'recaptcha'} className={'mb-3 mt-4'}>
                <div className={'d-flex justify-content-center align-items-center mb-4'}>
                    <Switch
                        id={CAPTCHA_SWITCH}
                        handle={handleValues}
                        isInvalid={trySubmit && !robot}
                        defaultValue={robot}
                        labelText={'I am not a robot'}
                    />
                </div>
            </Form.Group>

            <Button type="submit" className={'w-100'}>
                {t('register')}
            </Button>

            <Button onClick={() => navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)}
                    variant="outline-primary" type="submit" className={'w-100 mt-3'}>
                {t('cancel')}
            </Button>
            <div className="my-3 text-center col-figma-text-primary lh-20px"
                 style={{
                    borderRadius: '20px',
                    padding: '8px 18px',
                    border: '1px solid',
                    // borderColor: '#0061AF',
                    // color: '#0061AF',
                    // color: '#0d6efd',
                    borderColor: '#dc3545',
                    // borderColor: '#000',
                 }}
            >
                <strong>
                    {t('register_message')}
                </strong>
            </div>
        </Form>
    );
};

export default RegisterForm;
