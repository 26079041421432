import React, { useMemo }           from 'react';
import { useDispatch }              from "react-redux";
import { useTranslation }           from "react-i18next";
import { Button }                   from "react-bootstrap";

import { setAlert, setModalForm }   from "../../../storage/global";
import PayParcelsForm               from "../../forms/PayParcelsForm";

const PaySelectedButton = ({className, handle, children, selectedList, classNameBtn = ''}) => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const disabled = useMemo(() => !(selectedList?.length), [selectedList])

    function onClick(event) {
        if (typeof handle === 'function')
            handle(selectedList, event)

        if (!disabled) {

            if (Array.isArray(selectedList) && selectedList.length) {
                dispatch(setModalForm({
                    title: t('pay').slice(0, 1).toUpperCase() + t('pay').slice(1),
                    form: <PayParcelsForm parcels={selectedList}/>,
                    fullScreen: true
                }))
            } else {
                dispatch(setAlert({
                    text: t('nothingSelected'),
                    type: 'warning'
                }))
            }
        }
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <Button disabled={disabled} variant={'primary'} className={`${classNameBtn}`}>
                <span className={`mr-1 ${i18n.language === 'cn' ? 'fs-12px-i' : '' }`}>{t('pay_selected')}</span>
                <span className={`${i18n.language === 'cn' ? 'fs-12px-i' : '' }`}>{Number(selectedList?.length)}</span>
            </Button>
        </div>
    );
};

export default PaySelectedButton;
