import React, { useState } from 'react';

const TableItem = ({className, classNameText, handle, children, tooltip, item, hover, copy, tooltipFor = "my-tooltip-top"}) => {

    const [ref, setRef] = useState({});

    function onClick(event) {
        if (typeof handle === 'function') {
            handle(event, item)
        }
    }

    return (
        <>
            <div
                className={`${className ? className : ''} position-relative w-100`}
            >
                <div
                    onClick={onClick}
                    ref={ref => setRef(ref)}
                    onMouseOver={event => typeof hover === 'function' ? hover(event, ref) : null}
                    onMouseOut={event => typeof hover === 'function' ? hover(event, ref, false) : null}
                    data-tip={tooltip && tooltip.length > 11 ? tooltip : ""}
                    data-html="true"
                    data-for={tooltipFor}
                    className={`${classNameText ? classNameText : 'table-item-text'} text-minimise d-inline-block ${copy ? 'w-100' : ''}`} style={{
                        maxWidth: '100%'
                }}>{children}</div>
            </div>
        </>
    );
};

export default TableItem;
