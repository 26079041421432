
import React, { memo }          from 'react';
import { useTranslation }       from "react-i18next";
import { useSelector }          from "react-redux";

import СreateNewParcel              from "./СreateNewParcel";
import RefuseButton       from "./RefuseButton";
import InfoWithIcon       from "./InfoWithIcon";
import localStyles from './stylesActionBlock.module.scss'

const ActionBlock = (props) => {

    const {t} = useTranslation();

    return (
        <>
            {
                <div className={'p-1 justify-content-between'}>
                    <div className={'d-flex align-items-center p-0 p-md-1'}>
                        <СreateNewParcel handle={props.handleCreate} className={'ml-0 ml-md-5'} classNameBtn={localStyles.classNameBtn} classNameImg={'mr-05'}/>
                        <RefuseButton handle={props.handleRefuse} className={'ml-05 ml-md-5'} classNameBtn={localStyles.classNameBtn} classNameImg={'mr-05'}/>
                        <InfoWithIcon
                            className={'ml-05 ml-md-5 flex-0-1-50'}
                            classNameBtn={localStyles.classNameBtn}
                            classNameImg={'mr-05'}
                            dataClass={'unidentified-parcel'}
                            text={t('how_is_this_section_working')}
                            info={t('how_is_this_section_working_info')}
                        />
                    </div>
                </div>
            }
        </>
    )
};

export default memo(ActionBlock);
