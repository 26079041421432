import React, { useMemo }           from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button }                   from "react-bootstrap";
import { useTranslation }           from "react-i18next";

const SelectNotPayParcelButton = ({className, handle, classNameBtn = ''}) => {

    const {t, i18n} = useTranslation();

    const selectText = t('select_pay');
    const unSelectText = t('unselect_pay');

    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list)

    function onClick(e) {
        const modifyList = list.map(parcel => {
            if (parcel.is_paid === 'false')
                parcel.check = !isSelected;
            return parcel;
        })
        if (typeof handle === 'function')
            handle(e, modifyList);

    }

    const isSelected = useMemo(() => {
        return !!list.filter(parcel => parcel.check).length
    }, [list])

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <Button variant={'outline-primary'} className={`${classNameBtn}`}>
                <span className={`${i18n.language === 'cn' ? 'fs-12px-i' : '' }`}>{isSelected ? unSelectText : selectText}</span>
            </Button>
        </div>
    );
};

export default SelectNotPayParcelButton;
