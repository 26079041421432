import React, { memo }    from 'react';
import { useDispatch }    from "react-redux";
import { useTranslation } from "react-i18next";

import InputText          from "../input/InputText";
import { setSearch }      from "../../storage/global";

const SearchInput = ({className, handleSearch, classes = '', styles= {}, classNamesClear = 'top-5px'}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    function handle(value, name, ref) {
        if (typeof handleSearch === 'function')
            handleSearch(value, name, ref);

        dispatch(setSearch(value))
    }

    return (
        <div className={`${className ? className : ''}`} style={{minWidth: '140px'}}>
            <InputText handleBlur={handle}
                       id={'page-search'}
                       placeholder={t('search')}
                       closeButton={true}
                       styles={styles}
                       classes={`${classes}`}
                       classNamesClear={classNamesClear}
            />
        </div>
    );
};

export default memo(SearchInput);
