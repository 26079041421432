import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ReactTooltip        from "react-tooltip";

import {
    Promo_code_number,
} from "../../constants.js";

import CopyIcon from "../../components/shared/CopyIcon.jsx";

const PromoCodeNumberMobile = ({promoCode}) => {

    const {t} = useTranslation();

    const [refPC, setRefPC] = useState({});

    const tooltip = promoCode.is_active ? promoCode[Promo_code_number] : `<div class="wb-normal">${t('promo_code_will_become')}</div>`;

    function handleHover(event, ref_, isHover = true) {
        event.preventDefault();
        event.stopPropagation();
        if (isHover)
            ReactTooltip.show(ref_)
        else
            ReactTooltip.hide(ref_)
    }

    return (<>
        <span className={'name-column text-wrap text-bold fs-12px '}
              style={{
                display: 'inline-block',
                marginBottom: '3px'
              }}
        >
            {t('Promo_code_number')}
        </span>
        <br/>
        <div className={`d-flex align-items-center wfc`}
            data-tip={tooltip && tooltip.length > 11 ? tooltip : ""}
            data-html="true"
            data-for={promoCode.is_active ? "my-tooltip-top" : "my-tooltip-right"}
            ref={refPC => setRefPC(refPC)}
            onMouseOver={event => handleHover(event, refPC)}
            onMouseOut={event => handleHover(event, refPC, false)}
        >
            <span onClick={() => {}}
                  className={'text-minimise mr-1'}
                  style={promoCode.is_active ? {
                    fontSize: '10px',
                    lineHeight: '14px',
                    color: '#0060AE',
                    fontWeight: 700,
                } : {
                    fontSize: '10px',
                    lineHeight: '14px',
                    color: '#8493A8',
                    fontWeight: 700,
                }}
            >
                {promoCode[Promo_code_number]}
            </span>
            {promoCode.is_active ? <CopyIcon value={promoCode[Promo_code_number]} styles={{height: '20px'}}/> : null}
        </div>
    </>)
}

export default PromoCodeNumberMobile;
