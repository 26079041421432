import React, { memo }       from 'react';

import SendToWarehouseButton from "./SendToWarehouseButton";
import SearchInput           from "../../components/shared/SearchInput";
import FilterListBtn            from "../../components/shared/pages_buttons/FilterListBtn";
import AddNewButton          from "../../components/shared/pages_buttons/AddNewButton";
import EditButton            from "../../components/shared/pages_buttons/EditButton";
import DeleteButton          from "../../components/shared/pages_buttons/DeleteButton";
import PrintStickerButton    from "../../components/shared/pages_buttons/PrintStickerButton";
import ExportButton          from "../../components/shared/pages_buttons/ExportButton";
import ButtonStyleAction     from "../../components/shared/pages_buttons/ButtonStyleAction";
import SelectAllButton       from "../../components/shared/pages_buttons/SelectAllButton";

const ActionBlock = (props) => {

    return (
        <>
            <div className={'page-actions d-flex-mobile d-flex-mobile-none justify-content-between'}>
                <div className={'d-flex justify-content-start align-items-center'}>
                    <div className="">
                        <SelectAllButton isSelectedAll={props.isSelectedAll} handle={props.handle}  className={'mr-2 mb-2'} classNameBtn={'p-4px-12px'}/>
                        <SendToWarehouseButton isSelectedAll={props.isSelectedAll} className={'mr-2'} classNameBtn={'p-4px-12px ws-nowrap'}/>
                    </div>
                    <div className="">
                        <FilterListBtn className="mb-2"/>
                        <SearchInput className={"mr-2"} handleSearch={props.handleSearch} classes={'py-4px wunset'} styles={{height: 'unset'}}/>
                    </div>
                </div>
                <div className={'d-flex justify-content-end align-items-center'}>
                    <AddNewButton handle={props.handleAddNew} className={''}/>
                    <EditButton handle={props.handleEdit} className={'ml-5'}/>
                    <DeleteButton handle={props.handleDelete} className={'ml-5'} deleteFormData={props.deleteFormData}/>
                    <PrintStickerButton handle={props.handlePrint} className={'ml-5'} checkProcessed={true}/>
                    <ExportButton handle={props.handleExport} items={props.rootItems} className={'ml-5'} filename={props.exportFilename}/>
                    <ButtonStyleAction handle={props.openGroupSearch} className={'ml-5'} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
                </div>
            </div>
            <div className={'page-actions d-mobile'}>
                <div className={'d-flex justify-content-between align-items-center px-2 mb-3'}>
                    <SelectAllButton isSelectedAll={props.isSelectedAll} handle={props.handle} items={props.rootItems} className={'col-6'}/>
                    <AddNewButton isBorder handle={props.handleAddNew} className={''}/>
                </div>
                <SearchInput className={'col-12 px-2'} handleSearch={props.handleSearch}/>
                <div>
                    <SendToWarehouseButton className={'d-grid px-2 pt-3 w-100'}/>
                </div>
            </div>
        </>
    )
};

export default memo(ActionBlock);
