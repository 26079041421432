

import React, { useMemo, useState, useRef, useEffect }  from 'react';
import { useDispatch, useSelector }                     from "react-redux";
import ReactTooltip                                     from "react-tooltip";
import { useTranslation }                               from "react-i18next";

import ChargeForm                               from "../forms/ChargeForm";
import { setModalForm, setShowMenu, SET_KEY }   from "../../storage/global";
import NotificationDropDown                     from "./NotificationDropDown";
import { makeGetRequest }                       from "../../utils/api";

const NotifBtn = ({isMobile, classNameContainer}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const ref = useRef();

    const dataTip = t('Notification');

    const profileInfo = useSelector(state => state.global.profile);
    const closeNotificationForBirthday = useSelector(state => state.global.closeNotificationForBirthday);
    const poll = useSelector(state => state.global.poll);
    const balanceNotPaid = useSelector(state => state.global.balanceNotPaid);
    const lostList = useSelector(state => state.global.lost.list)
    const promoCodesList = useSelector(state => state.global.promoCodesList);

    useEffect( () => {
        if(profileInfo && !profileInfo?.birthday) {
            makeGetRequest(`/api/misc/getCloseNotificationForBirthday`).then((res) => {
                let closeNotificationForBirthday = []
                if(res.data && res.data.uris && res.data.uris.length) {
                    closeNotificationForBirthday = res.data.uris;
                }
                dispatch({type: SET_KEY, payload: {
                    key: 'closeNotificationForBirthday',
                    value: closeNotificationForBirthday,
                }})
            }).catch(err => {
                console.log('getCloseNotificationForBirthday err');
            });
        }
    }, [profileInfo?.birthday])

    const notifsCount = useMemo(() => {
        let count = 0;
        if (Number(balanceNotPaid.debt) > 0) count++;
        if (poll.id) count++;
        // if (lostList.length) count = count + lostList.length;
        if (lostList.length) count++;
        if (!profileInfo?.birthday && closeNotificationForBirthday && closeNotificationForBirthday.length < 6) count++;
        const promoCodeNotifications = [];
        promoCodesList.forEach((promoCode) => {
            const nowTime = new Date();
            const finishDate = new Date(promoCode.finishDate);
            const diffTime = Math.abs(finishDate - nowTime);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
            if(!diffDays && (promoCode.expireMessageCn || promoCode.expireMessageEn)) count++;
            else if(promoCode.messageCn || promoCode.messageEn) {
                if(!promoCodeNotifications.includes(promoCode.messageEn))count++;
                if(promoCode.messageEn === 'You have been assigned a personal referral promo code. Take advantage of the opportunity to earn bonuses for each new user you invite!') {
                    promoCodeNotifications.push(promoCode.messageEn);
                }
            }
        })
        return count;
    }, [balanceNotPaid, poll, lostList, profileInfo?.birthday, closeNotificationForBirthday, promoCodesList]);

    const switchForm = useMemo(() => {
        return (showDropDown, isCloseIcon) => {
            setShowNotificationDropDown(showDropDown);
            if(!profileInfo?.birthday && isCloseIcon && closeNotificationForBirthday && closeNotificationForBirthday.length < 6) {
                makeGetRequest(`/api/misc/kk_analytics`, {
                    action: 'closeNotificationForBirthday'
                }).catch(err => {
                    console.log('makeGetRequest err');
                });
            }
        };
    }, [profileInfo?.birthday, closeNotificationForBirthday]);

    const [showNotificationDropDown, setShowNotificationDropDown] = useState(false)

    function openForm() {
        setShowNotificationDropDown(!showNotificationDropDown);
    }

    return (
        <div className={`d-flex p-relative ${classNameContainer}`}>
            <div
                ref={ref}
                data-tip={isMobile ? undefined : dataTip}
                onClick={() => openForm()}
                className={`p-relative cursor-pointer d-flex me-2 notifsBell`}
            >
                <div className={`position-relative`}>
                    <img src="/assets/img/bell.svg" alt="notif" height="24px" width="24px"/>
                    {!notifsCount ? null : <div className={`notifsBellCount`}>
                        {notifsCount}
                    </div>}
                </div>
                {isMobile ? <span className={`ml-2`}>{dataTip}</span> : ''}
            </div>
            <NotificationDropDown show={showNotificationDropDown} close={(count_number_of_clicks) => switchForm(!showNotificationDropDown, count_number_of_clicks)} parentElementRef={ref}/>
        </div>
    );
};

export default NotifBtn;
