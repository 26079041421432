
import React, { useState, useEffect }        from 'react';
import { useTranslation }         from "react-i18next";
import { useDispatch, useSelector }            from "react-redux";

import {
    DEFAULT_ERROR_OBJECT,
    SEND_BY,
    TO_COUNTRY,
    ESTIMATED_DELIVERY,
    TRACKER,
    SERVICE_TYPE,
    OPERATION,
} from "../../constants";
import ModalButtons                         from "./modal/ModalButtons";
import Select                               from "../input/Select";
import { hideModalForm,
    accountingFilters,
    newParcelsFilters,
    sentParcelsFilters,
} from "../../utils/misc";
import { SET_KEY }       from "../../storage/global";

const ModalFilterOption = ({page = '', isMobile}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const countries = useSelector(state => state.global.countries);
    const list = useSelector(state => state.global.list);

    const filtersList = page === 'accounting' ? accountingFilters(t)
    : [
        ...newParcelsFilters(t),
        ...(page === 'sentParcels' ? sentParcelsFilters(t) : []),
    ];

    const filtersListOptionsDef = {
        [SEND_BY]: [
            {
                keySubfilter: 'AIR',
                nameSubfilter: 'AIR',
            },
            {
                keySubfilter: 'SEA',
                nameSubfilter: 'SEA',
            },
            {
                keySubfilter: 'TRUCK',
                nameSubfilter: 'TRUCK',
            },
        ],
        [TO_COUNTRY]: [],
        [SERVICE_TYPE]: [
            {
                keySubfilter: '1',
                nameSubfilter: t('PUDO'),
            },
            {
                keySubfilter: '0',
                nameSubfilter: t('toDoor'),
            },
        ],
        [TRACKER]: [],
        [ESTIMATED_DELIVERY]: [],
        [OPERATION]: [],
    };

    function getStatusesAndEstimatedDelivery(list) {
        return list.reduce( (beforeArr, parcel) => {
            const newArr = beforeArr;
            if(!newArr[0].includes(parcel[TRACKER])) newArr[0].push(parcel[TRACKER]); // status
            if(typeof parcel[ESTIMATED_DELIVERY] === 'string')
                if(!newArr[1].includes(parcel[ESTIMATED_DELIVERY])) newArr[1].push(parcel[ESTIMATED_DELIVERY]);
            if(!newArr[2].includes(parcel[OPERATION])) newArr[2].push(parcel[OPERATION]);
            if(!newArr[3].includes(parcel[TO_COUNTRY])) newArr[3].push(parcel[TO_COUNTRY]);
            return newArr;
        }, [[],[],[],[]])
    }

    function setFilterSelected(value) {
        dispatch({type: SET_KEY, payload: {
            key: 'filterSelected',
            value,
        }})
    }

    function setSubfilterSelected(value) {
        dispatch({type: SET_KEY, payload: {
            key: 'subfilterSelected',
            value,
        }})
    }

    function getFilterListOptions() {
        let filterListOptions_ = filtersListOptionsDef;
        const [newStatuses_, newEstimatedDeliveres_, newOperation_, newCountry_] = getStatusesAndEstimatedDelivery(list)
        let newStatuses = newStatuses_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        let newEstimatedDeliveres = newEstimatedDeliveres_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        let newOperation = newOperation_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        let newCountry = newCountry_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        filterListOptions_[TRACKER] = newStatuses;
        filterListOptions_[ESTIMATED_DELIVERY] = newEstimatedDeliveres;
        filterListOptions_[OPERATION] = newOperation;
        filterListOptions_[TO_COUNTRY] = newCountry;
        return filterListOptions_
    }

    const [localFilterSelected, setLocalFilterSelected] = useState(page === 'accounting' ? OPERATION : SEND_BY)
    const [localSubfilterSelected, setLocalSubfilterSelected] = useState(page === 'accounting' ? '' : 'AIR')
    const [filterListOptions, setFilterListOptions] = useState(getFilterListOptions())
    const [errorSubfilter, setErrorSubfilter] = useState({...DEFAULT_ERROR_OBJECT});

    useEffect(() => {
        if(list) {
            const filterListOptions_ = getFilterListOptions();
            setFilterListOptions(filterListOptions_);
            setLocalSubfilterSelected(filterListOptions_[localFilterSelected][0]?.keySubfilter)
        }
    }, [list, localFilterSelected])

    function clearFilter() {
        dispatch({type: SET_KEY, payload: {
            key: 'applyFilter',
            value: false,
        }})
        setFilterSelected(filtersList[0].keyFilter);
        const subfilterSelected = filterListOptions[filtersList[0].keyFilter][0]
        setSubfilterSelected(subfilterSelected?.keySubfilter);
        hideModalForm(dispatch)();
    }

    function saveAndFilter() {
        setFilterSelected(localFilterSelected);
        setSubfilterSelected(localSubfilterSelected);
        dispatch({type: SET_KEY, payload: {
            key: 'applyFilter',
            value: true,
        }})
        hideModalForm(dispatch)()
    }

    return (
        <div className=''>
            <div className="firtersOptions row">
                <Select
                    id={'filters'}
                    classNameContainer={'col-12 col-md-6'}
                    className={''}
                    options={filtersList}
                    selectValue={filtersList.find((el) => el.keyFilter == localFilterSelected)}
                    errorMessage={''}
                    loaded={false}
                    keyName={'keyFilter'}
                    nameValue={'nameFilter'}
                    handleChange={(el, id) => {
                        setLocalFilterSelected(el.keyFilter)
                    }}
                />
                <Select
                    id={'filtersOptions'}
                    className={'maxh-170'}
                    classNameContainer={`col-12 col-md-6 ${isMobile ? 'mt-2' : ''}`}
                    options={filterListOptions[localFilterSelected]}
                    selectValue={filterListOptions[localFilterSelected].find((el) => el.keySubfilter == localSubfilterSelected)}
                    errorMessage={errorSubfilter.message}
                    // loaded={loadSubfilter}
                    keyName={'keySubfilter'}
                    nameValue={'nameSubfilter'}
                    handleChange={(el, id) => {
                        setLocalSubfilterSelected(el.keySubfilter)
                    }}
                />
            </div>
            <ModalButtons buttons={{
                cancel: {
                    text: t('clear'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: clearFilter,
                },
                save: {
                    text: t('Apply_settings'),
                    variant: 'primary',
                    type: 'button',
                    action: saveAndFilter
                }
            }}/>
        </div>
    );
};

export default ModalFilterOption;