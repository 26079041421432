import React          from 'react';

import ArrowSortBlock from "./tables_elements/ArrowSortBlock";
import { ASC, DESC }  from "../../constants";

const ItemHeadList = ({onClick, text, col, sortArrow, size, name, order, children, classNames, classNamesSortBlock = 'ml-1 mr-1'}) => {
    return (
        <div
            onClick={onClick}
            className={`${typeof col === 'number' ? 'my-col-' + col : col} d-flex align-items-center p-2 cursor-pointer ${classNames}`}>
            <div>
                {
                    children ? children : <span>{text}</span>
                }
            </div>
            {
                sortArrow === false
                    ?
                    null
                    :
                    <ArrowSortBlock
                        className={`${classNamesSortBlock}`}
                        size={size ?? 10}
                        name={name}
                        currentName={order.name}
                        direction={order.direction}
                        directionList={[ASC, DESC]}
                    />
            }
        </div>
    );
};

export default ItemHeadList;
