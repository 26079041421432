import React, { useMemo }       from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useTranslation }  from "react-i18next";
import { Button }          from "react-bootstrap";

import { setModalForm, SET_KEY } from "../../../storage/global";
import ModalFilterOption from "../ModalFilterOption";
import CloseInputButton from "../CloseInputButton";
import {
    accountingFilters,
    newParcelsFilters,
    sentParcelsFilters,
} from "../../../utils/misc";
import {
    SEND_BY,
    OPERATION,
} from "../../../constants";

const FilterListBtn = ({className = '', page, isMobile = false, isHighBtn = false}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const filterSelected = useSelector(state => state.global.filterSelected);
    const applyFilter = useSelector(state => state.global.applyFilter);

    const filtersList = page === 'accounting' ? accountingFilters(t)
    : [
        ...newParcelsFilters(t),
        ...(page === 'sentParcels' ? sentParcelsFilters(t) : []),
    ];

    const selectedFilterText = useMemo(() => {
        const filterSelectedExist = filtersList.find((el) => el.keyFilter == filterSelected)
        const compareKey = page === 'accounting' ? OPERATION : (filterSelectedExist?.keyFilter || SEND_BY)
        return filtersList.find((el) => el.keyFilter == compareKey)?.nameFilter;
    }, [filtersList, filterSelected])

    function openFilterOption() {
        dispatch({type: SET_KEY, payload: {
            key: 'applyFilter',
            value: false,
        }})
        dispatch(setModalForm({
            title: t('Choose_filtering_variants'),
            form: <ModalFilterOption page={page} isMobile={isMobile}/>
        }))
    }

    function clearFilter(event) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({type: SET_KEY, payload: {
            key: 'applyFilter',
            value: false,
        }})
        dispatch({type: SET_KEY, payload: {
            key: 'filterSelected',
            value: filtersList[0].keyFilter,
        }})
    }

    return (
        <div className={`${className}`}>
            <Button
                style={{
                    width: isMobile ? '100%' : '220px',
                    height: isMobile ? '32px' : isHighBtn ? '42px' : '34px',
                    display: 'flex',
                    alignItems: 'center',
                }}
                className={`position-relative ${isMobile ? '' : 'mr-2'} p-4px-5px text-500 text-left-important bor-rad-20px padding-when-clear-search-button-viewed`}
                onClick={openFilterOption}
                type={'button'}
                variant={'outline-primary'}
            >
                <img className={'mr-1'} src="/assets/img/filter.png" alt="export-button" height={isMobile ? '24px' : isHighBtn ? '34px' : "26px"}/>
                <span
                    style={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >   {t('Filter')+(isMobile || !applyFilter ? '' : ': '+selectedFilterText)}
                </span>
                <CloseInputButton clear={clearFilter}
                                  closeButton={applyFilter}
                                  classNames={'top-3px'}
                />
            </Button>
        </div>
    );
};

export default FilterListBtn;
