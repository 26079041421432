
import React, { memo }          from 'react';
import { useTranslation }       from "react-i18next";
import { useSelector }          from "react-redux";

import SearchInput              from "../../components/shared/SearchInput";
import MyDatePicker             from "../../components/shared/datepicker/MyDatePicker";
import FilterListBtn               from "../../components/shared/pages_buttons/FilterListBtn";
import ExportButton             from "../../components/shared/pages_buttons/ExportButton";
import PaySelectedButton        from "../../components/shared/pages_buttons/PaySelectedButton";
import SelectNotPayParcelButton from "../../components/shared/pages_buttons/SelectNotPayParcelButton";
import ButtonStyleAction        from "../../components/shared/pages_buttons/ButtonStyleAction";
import PrintStickerButton       from "../../components/shared/pages_buttons/PrintStickerButton";

const ActionBlock = (props) => {

    const {t} = useTranslation();

    const isMobile = useSelector(state => state.global.isMobile)

    return (
        <>
            {
                isMobile
                    ?
                    <div className={'page-actions d-mobile'}>
                        <div className={'d-flex justify-content-between align-items-center p-2 gap10'}>
                            <SelectNotPayParcelButton handle={props.handleSelectedToPay}/>
                            <PaySelectedButton handle={props.handlePay} selectedList={props.selectedList}/>
                        </div>
                        <div>
                            <SearchInput className={'w-100 mb-3 mt-2'} handleSearch={props.handleSearch}/>
                        </div>
                        <div className={'d-flex justify-content-between align-items-center p-2'}>
                            <MyDatePicker
                                handle={props.handleDatePicker}
                                startEnd
                                className={'d-flex'}
                                classNameFirstDate={'px-2'}
                                classNameSecondDate={'px-2'}
                                colFirst={['12', 'auto']}
                                colSecond={['12', 'auto']}
                                textFirst={t('date_from')}
                                textSecond={t('date_to')}
                            />
                        </div>
                    </div>
                    :
                    <div className={'page-actions d-flex-mobile d-flex-mobile-none justify-content-between'}>
                        <div className={'d-flex justify-content-start align-items-center mr-3 ws-nowrap'}>
                            <dic className="">
                                <SelectNotPayParcelButton handle={props.handleSelectedToPay} className={'mr-2 mb-2'} classNameBtn={'p-4px-12px ws-nowrap'}/>
                                <PaySelectedButton handle={props.handlePay} selectedList={props.selectedList} className={'mr-2'} classNameBtn={'p-4px-12px ws-nowrap'}/>
                            </dic>
                            <div className="">
                                <FilterListBtn page={'sentParcels'} className="mb-2"/>
                                <SearchInput className={"minw-150 mr-2"} handleSearch={props.handleSearch} classes={'py-4px wunset'} styles={{height: 'unset'}}/>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-end align-items-center'}>
                            <MyDatePicker
                                handle={props.handleDatePicker}
                                startEnd
                                className={'d-flex'}
                                stylesFirstDate={{marginRight: '15px'}}
                                classNameFirstDate={'mr-3'}
                                textFirst={t('date_from')}
                                textSecond={t('date_to')}
                                colFirst={['auto']}
                                colSecond={['auto']}
                                TitleCClassNames={"pl-15 "}
                                TitleClassNames={"lh-0"}
                                DPCClassNames={"mtb-9 minw-105 w-100px col"}
                            />
                            <ExportButton handle={props.handleExport} items={props.rootItems} className={'ml-5'} buttClassNames={""} filename={props.exportFilename}/>
                            <PrintStickerButton handle={props.handlePrint} className={'ml-5'}/>
                            <ButtonStyleAction handle={props.openGroupSearch} className={'ml-5'} buttClassNames={""} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
                        </div>
                    </div>
            }
        </>
    )
};

export default memo(ActionBlock);
