
import axiosRoot        from 'axios'

import axios            from "../lib/axios";
import { setTrackers, setStatuses } from "../storage/global";
import statuses from '../resources/statuses'

export function login(data) {
    return axios
        .post('/api/auth/login', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function logout(data) {
    return axios
        .post('/api/auth/logout', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function restorePass(data) {
    return axios
        .post('/api/auth/restorePass', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function signup(data) {
    return axios
        .post('/api/auth/signup', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getUserInfo(data) {
    return axios
        .get('/api/user/getInfo', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function updateUserInfo(data) {
    return axios
        .post('/api/user/update', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getBalance(data) {
    return axios
        .get('/api/user/getBalance', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getWaitingArrivalParcels(data) {
    return axios
        .get('/api/waitingArrival/getList', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function deleteWaitingArrivalParcels(data) {
    return axios
        .post('/api/waitingArrival/delete', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getNewParcels(data) {
    return axios
        .get('/api/newParcels/getList', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function sendToWarehouse(data) {
    return axios
        .post('/api/misc/sendToWareHose', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function deleteNewParcels(data) {
    return axios
        .post('/api/newParcels/delete', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function exportNewParcels(data) {
    return axios
        .post('/api/newParcels/export', data, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch(data)
}

export function exportWaitingArrival(data) {
    return axios
        .post('/api/waitingArrival/export', data, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch(data)
}

export function exportSentParcels(data) {
    return axios
        .post('/api/sentParcels/export', data, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch(data)
}

export function exportAccounting(data) {
    return axios
        .post('/api/accounting/export', data, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch(data)
}

export function printStickers(data) {
    return axios
        .post('/api/misc/printStickers', data, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getPrintPreset(data) {
    return axios
        .get('/api/misc/getPrintPreset', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getParcelInfo(data) {
    return axios
        .get('/api/newParcels/getInfo', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getCountryList(data) {
    return axios
        .get('/api/misc/getCountryList', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export async function saveCountryList(data) {
    return axios
        .post('/api/admin/saveCountryList', {
            ...data
        })
        .then(response => {
            return response;
        })
        .catch( function (err) {
            console.log('err: ', err)
        })
}

export function getDangerousGoods(data) {
    return axios
        .get('/api/misc/getDangerousGoods', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export async function saveDangerWorlds(params) {
    return axios
        .post('/api/admin/saveDangerWorlds', {
            ...params
        })
        .then(response => {
            return response;
        })
        .catch(params)
}

export function getSentParcels(data) {
    return axios
        .get('/api/sentParcels/getList', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getAccounting(data) {
    return axios
        .get('/api/accounting/getList', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getManagerList(data) {
    return axios
        .get('/api/misc/getManagerList', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getInsuranceCountry(data) {
    return axios
        .get('/api/misc/getInsuranceCountry', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getLostParsels(data) {
    return axios
        .get('/api/misc/getLostParsels', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function refuseLostParcel(data) {
    return axios
        .get('/api/misc/refuseLostParcel', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getCostInsurance(data) {
    return axios
        .post('/api/misc/getInsuranceCost', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function createUpdateNewParcel(data) {
    return axios
        .post('/api/newParcels/createUpdate', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function importNewParcelFile(data, config) {
    return axios
        .post('/api/newParcels/import', data, config)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function createPayment(data, config) {
    return axios
        .post('/api/alipay/createPayment', data, config)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function createPaymentWithBalance(data, config) {
    return axios
        .post('/api/misc/payParcelsWithBalance', data, config)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getPhoneMask(data) {
    return axios
        .get('/api/misc/getPhoneMask', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}
export async function savePhoneMask(data) {
    return axios
        .get('/api/misc/savePhoneMask', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getExcludedGoods(data) {
    return axios
        .get('/api/misc/getExcludedGoods', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getDeniedZip(data) {
    return axios
        .get('/api/misc/getDeniedZip', {
            params: data
        })
        .then(response => {
            return response;
        })
        .catch(data)
}

export function calculateTotalPay(data) {
    return axios
        .post('/api/misc/calculateTotalPay', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getNotPaidParcels(data) {
    return axios
        .get('/api/misc/getNotPaidParcels', data)
        .then(response => {
            return response;
        })
        .catch(data)
}

export function getTrackers(numbers, dispatch, noStatus) {
    return new Promise(function (resolve, reject) {
        const url_en = process.env.TRACK_URL || 'https://track.meest.cn';
      return resolve(!numbers || !numbers.length ? null
        : axios.post(`${url_en}/api/tracks/list/`, {
            "shipments": numbers
        }, {withCredentials: false})
        .then(({data}) => {
            dispatch(setTrackers(getlastAction(data), noStatus))
            return;
        })
        .catch(err => {
          console.log('getTrackers err: ', err);
          return {errTrackByNumber: err}
        }))
    });

}

function getlastAction(data) {
    const newData: IObj = {};
    const sortRes = data.sort( (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    sortRes.forEach((status) => {
        if(!newData[status.shipment]) {
            newData[status.shipment] = {
                shipment: status.shipment,
                laststatus: {
                    id: status.id,
                    date: status.date,
                    status: status.status, // code
                    country: status.country,
                    city: status.city
                }
            }
        }
    });
    return newData
}

export function loadStatuses(dispatch) {
    const url_en = process.env.TRACK_URL || 'https://track.meest.cn';
    axios.get(`${url_en}/api/v1/status/list/`, {},{}).then(({data}) => {
        dispatch(setStatuses(data));
    })
    .catch(err => {
      console.log('loadStatuses err: ', err);
      return {errTrackByNumber: err}
    })
}

export function saveAnswer(data) {
    return axios
        .post('/api/admin/saveAnswers', data)
        .then(({data}) => data)
}

export function getLastActiveQuestionTemplate() {
    return axios
        .get('/api/admin/getLastActiveQuestionTemplate')
        .then(({data, status}) => {
            return {data, status }
        })
        .catch(err => {
            console.log('getLastActiveQuestionTemplate err: ', err)
            return err
        })
}

export function getListPoll(data) {
    return axios
        .get('/api/admin/getListQuestionTemplate', {
            params: data
        })
        .then(response => {
            // console.log("getListPoll: ", response)
            return response;
        })
        .catch(data)
}

export function getQueryStatisticsList(params) {
    return axios
        .post('/api/admin/getQueryStatistics', params)
        .then(response => {
            // console.log("getQueryStatistics: ", response)
            return response;
        })
        .catch()
}

export function getQueryStatisticsListAvg(params) {
    return axios
        .post('/api/admin/getQueryStatisticsAvg', params)
        .then(response => {
            // console.log("getQueryStatisticsAvg: ", response)
            return response;
        })
        .catch()
}

export function getRequestsUris() {
    return axios
        .post('/api/admin/getRequestsUriList', { params: [] })
        .then(response => {
            // console.log("getRequestsUriList: ", response)
            return response;
        })
        .catch()
}

export function getClientIds() {
    return axios
        .post('/api/admin/getClientIds', { params: [] })
        .then(response => {
            // console.log("getClientIds: ", response)
            return response;
        })
        .catch()
}

export async function saveWarningNotificationEmails(params) {
    return axios
        .post('/api/admin/saveWarningNotificationEmails', params)
        .then(response => {
            return response;
        })
        .catch(params)
}

export function getWarningNotificationEmails() {
    return axios
        .get('/api/admin/getWarningNotificationEmails')
        .then(response => {
            return response;
        })
        .catch()
}

export async function saveMemoryLimits(params) {
    return axios
        .post('/api/admin/saveMemoryLimits', params)
        .then(response => {
            return response;
        })
        .catch(params)
}

export function getMemoryLimits() {
    return axios
        .get('/api/admin/getMemoryLimits')
        .then(response => {
            return response;
        })
        .catch()
}

export async function saveNotificationEmailsMemoryLimit(params) {
    return axios
        .post('/api/admin/saveNotificationEmailsMemoryLimit', params)
        .then(response => {
            return response;
        })
        .catch(params)
}

export function getNotificationEmailsMemoryLimit() {
    return axios
        .get('/api/admin/getNotificationEmailsMemoryLimit')
        .then(response => {
            return response;
        })
        .catch()
}

export async function saveWarningNotificationLimits(params) {
    return axios
        .post('/api/admin/saveWarningNotificationLimits', params)
        .then(response => {
            return response;
        })
        .catch(params)
}

export function getWarningNotificationLimits() {
    return axios
        .get('/api/admin/getWarningNotificationLimits')
        .then(response => {
            return response;
        })
        .catch()
}

export function checkLogMemory() {
    return axios
        .get('/api/admin/checkLogMemory')
        .then(response => {
            return response;
        })
        .catch()
}

export function getReqStatisticExel(params) {
    return axios
        .post('/api/admin/loadReqStatisticExcel', params, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch()
}

export function getResponsibleEmails() {
    return axios
        .get('/api/admin/getResponsibleEmails')
        .then(response => {
            // console.log("getResponsibleEmails: ", response)
            return response;
        })
        .catch()
}

export function createResponsibleEmail(data) {
    return axios
        .post('/api/admin/createResponsibleEmail', data)
        .then(response => {
            // console.log("createResponsibleEmail: ", response)
            return response;
        })
        .catch()
}

export function editResponsibleEmail(data) {
    return axios
        .post('/api/admin/editResponsibleEmail', data)
        .then(response => {
            // console.log("editResponsibleEmail: ", response)
            return response;
        })
        .catch()
}

export function deleteResponsibleEmail(data) {
    return axios
        .post('/api/admin/deleteResponsibleEmail', data)
        .then(response => {
            // console.log("deleteResponsibleEmail: ", response)
            return response;
        })
        .catch()
}

export function deletePoll(data) {
    return axios
        .post('/api/admin/deleteQuestion', data)
        .then(({data, status}) => {
            return {data, status }
        })
        .catch(err => {
            // console.log('deletePoll err: ', err)
            return err
        })
}

export function clearCach(data) {
    return axios
        .post('/api/admin/clearCach', data)
        .then(({data, status}) => {
            return {data, status }
        })
        .catch(err => {
            console.log('clearCach err: ', err)
            return err
        })
}

export function getProfileSettings() {
    return axios
        .get('/api/settings/profileSettings')
        .then(({data, status}) => {
            return {data, status }
        })
        .catch(err => {
            // console.log('getProfileSettings err: ', err)
            return err
        })
}

export function sendSms(data, orEmail = false) {
    return axios
        .post(`/api/auth/signup/${orEmail ? 'sendSmsOrEmail' : 'sendSms'}`, data)
        .then((res) => {
            const {data, status} = res;
            return {data: data.connection_id ? {connection_id: data.connection_id} : data.body ? JSON.parse(data.body).response : '', status }
        })
        .catch(err => {
            console.log('sendSmsOrEmail err: ', err)
            return err
        })
}

export function checkCode(connection, code) {
    return axios
        .post('/api/auth/signup/checkCode', {connection, code})
        .then((res) => {
            const {data, status} = res;
            return data ? data : {data: null, status }
        })
        .catch(err => {
            console.log('checkCode err: ', err)
            return err
        })
}

export function checkPhoneNumExistKK({phone, user_id}) {
    return axios
        .post('/api/auth/signup/checkPhoneNumExistKK', {phone, user_id} )
        .then((res) => {
            const {data, status} = res;
            return data ? data : { status }
        })
        .catch(err => {
            console.log('checkPhoneNumExistKK err: ', err)
            return err
        })
}

export function checkEmailExistKK({email, user_id}) {
    return axios
        .post('/api/auth/signup/checkEmailExistKK', {email, user_id} )
        .then((res) => {
            const {data, status} = res;
            return data ? data : { status }
        })
        .catch(err => {
            console.log('checkEmailExistKK err: ', err)
            return err
        })
}

export function getGoodTypeByCommodity(data) {
    return axios
        .post('/api/misc/CategoryByDescription', data)
        .then(({data, status}) => {
            return {data, status }
        })
        .catch(err => {
            console.log('getGoodTypeByCommodity err: ', err)
            return err
        })
}

export function getNotificationSettings() {
    return axios
        .get('/api/settings/notificationSettings')
        .then(({data, status}) => {
            return {data, status }
        })
        .catch(err => {
            // console.log('deletePoll err: ', err)
            return err
        })
}


export function getForbiddenCommodity() {
    return axios
        .get('/api/misc/getForbiddenCommodity')
        .then(({data, status}) => {
            return {data, status }
        })
        .catch(err => {
            // console.log('deletePoll err: ', err)
            return err
        })
}

export async function makeGetRequest(url, data = {}) {
    return axios.get(url, {
        params: data
    })
        .then(response => {
            // console.log(`url: `, response)
            return response;
        })
        .catch(err => {
            // console.log('makeGetRequest err: ', err)
            return err
        })
}

export async function makePostRequest(url, params, bodyReq) {
    return axios.post(url, params, bodyReq)
        .then(response => {
            // console.log(`url: `, response)
            return response;
        })
        .catch(err => {
            // console.log('makePostRequest err: ', err)
            return err
        })
}
