import React from 'react';

const Api = () => {

    const list = [
        {
            title: 'Login',
            url: '/api/auth/login',
            method: 'POST',
            contentType: 'application/json',
            description: 'Логинация. Метод для получения токена.',
            data: [
                {
                    name: 'email',
                    type: 'string',
                    example: 'email@gmail.com'
                },
                {
                    name: 'password',
                    type: 'string',
                    example: 'efe8f34J38'
                }
            ],
        },
        {
            title: 'Logout',
            url: '/api/auth/logout',
            method: 'POST',
            contentType: 'application/json',
            description: 'Метод для удаления токена.',
            data: [],
        },
        {
            title: 'Signup',
            url: '/api/auth/signup',
            method: 'POST',
            contentType: 'application/json',
            description: 'Регистрация нового клиента',
            data: [
                {
                    name: 'email',
                    type: 'string',
                    example: 'email@gmail.com'
                },
                {
                    name: 'name',
                    type: 'string',
                    example: 'Taras Voroniuk'
                },
                {
                    name: 'phone',
                    type: 'string',
                    example: '+380976246127'
                },
                {
                    name: 'password',
                    type: 'string',
                    example: 'efe8f34J38'
                },
                {
                    name: 'password_confirmation',
                    type: 'string',
                    example: 'efe8f34J38'
                },
                {
                    name: 'manager',
                    type: 'string',
                    example: 're7ree87re8r7e8r7e...'
                },
            ],
        },
        {
            title: 'Restore',
            url: '/api/auth/restore',
            method: 'POST',
            contentType: 'application/json',
            description: 'Восстановление пароля',
            data: [
                {
                    name: 'email',
                    type: 'string',
                    example: 'email@gmail.com'
                },
            ],
        },
        {
            title: 'New Parcels. Get List',
            url: '/api/newParcels/getList',
            method: 'GET',
            contentType: 'application/json',
            description: 'Получения списка новых посылок',
            data: [],
        },
        {
            title: 'New Parcels. Get Info',
            url: '/api/newParcels/getInfo',
            method: 'GET',
            contentType: 'application/json',
            description: 'Получения деталей новой посылки',
            data: [
                {
                    name: 'uid',
                    type: 'string',
                    example: 'd1497885-d202-11ec-bb97-001dd8b73623'
                }
            ],
        },
        {
            title: 'New Parcels. Create or Update',
            url: '/api/newParcels/createUpdate',
            method: 'POST',
            contentType: 'application/json',
            description: 'Создание или редактирования посылки в зависимости от наличия uid посылки. Если есть, то редактирования, если нет то создание.',
            data: [
                {
                    name: 'uid (optional)',
                    type: 'string',
                    example: 'd1497885-d202-11ec-bb97-001dd8b73623'
                },
                {
                    name: 'Commodity',
                    type: 'string',
                    example: 'Phone'
                },
                {
                    name: 'Passport',
                    type: 'string',
                    example: ''
                },
                {
                    name: 'TaxIdNumber',
                    type: 'string',
                    example: ''
                },
                {
                    name: 'address',
                    type: 'string',
                    example: 'Geroiv Krut 16'
                },
                {
                    name: 'city',
                    type: 'string',
                    example: 'Brovary'
                },
                {
                    name: 'coment',
                    type: 'string',
                    example: ''
                },
                {
                    name: 'email',
                    type: 'string',
                    example: 'email@gmail.com'
                },
                {
                    name: 'hs_code',
                    type: 'string',
                    example: '1212121212'
                },
                {
                    name: 'passport_num',
                    type: 'string',
                    example: ''
                },
                {
                    name: 'quantity',
                    type: 'string',
                    example: '1'
                },
                {
                    name: 'reciver',
                    type: 'string',
                    example: 'Taras Voroniuk'
                },
                {
                    name: 'region',
                    type: 'string',
                    example: 'Kyiv region'
                },
                {
                    name: 'sensitive_goods',
                    type: 'string',
                    example: '1'
                },
                {
                    name: 'ship',
                    type: 'string',
                    example: 'SEA'
                },
                {
                    name: 'sku',
                    type: 'string',
                    example: '214'
                },
                {
                    name: 'tel',
                    type: 'string',
                    example: '0935857066'
                },
                {
                    name: 'to',
                    type: 'string',
                    example: 'UA'
                },
                {
                    name: 'url',
                    type: 'string',
                    example: ''
                },
                {
                    name: 'value',
                    type: 'string',
                    example: '10,53'
                },
                {
                    name: 'weight',
                    type: 'string',
                    example: '1,2'
                },
                {
                    name: 'zip',
                    type: 'string',
                    example: '31246'
                },
                {
                    name: 'IOSS_Number',
                    type: 'string',
                    example: 'IM1234567890'
                },
            ],
        },
        {
            title: 'New Parcels. Delete',
            url: '/api/newParcels/delete',
            method: 'POST',
            contentType: 'application/json',
            description: 'Удаление посылки',
            data: [
                {
                    name: 'uids',
                    type: 'array',
                    example: ['[d1497885-d202-11ec-bb97-001dd8b73623, d1497885-d202-11ec-bb97-001dd8b73623]']
                }
            ],
        },
        {
            title: 'New Parcels. Import',
            url: '/api/newParcels/import',
            method: 'POST',
            contentType: 'multipart/form-data',
            description: 'Импорт посылок с файла. Шаблон файла для заполнения посылок можно скачать в кабинете в шапке.',
            data: [
                {
                    name: 'file',
                    type: 'file',
                    example: ''
                }
            ],
        },
        {
            title: 'New Parcels. Export',
            url: '/api/newParcels/export',
            method: 'POST',
            contentType: 'application/json',
            description: 'Загрузка посылок в файле Excel (.xlsx)',
            data: [
                {
                    name: 'no name',
                    type: 'array',
                    example: 'Отправить массив с посылками которые были получены на запрос "getList"'
                }
            ],
        },
        {
            title: 'Waiting Arrival. Get List',
            url: '/api/waitingArrival/getList',
            method: 'GET',
            contentType: 'application/json',
            description: 'Список посылок',
            data: [],
        },
        {
            title: 'Waiting Arrival. Export',
            url: '/api/waitingArrival/export',
            method: 'GET',
            contentType: 'application/json',
            description: 'Загрузка посылок в файле Excel (.xlsx)',
            data: [],
        },
        {
            title: 'Waiting Arrival. Delete',
            url: '/api/waitingArrival/delete',
            method: 'POST',
            contentType: 'application/json',
            description: 'Удаление посылки',
            data: [
                {
                    name: 'uids',
                    type: 'array',
                    example: ['[d1497885-d202-11ec-bb97-001dd8b73623, d1497885-d202-11ec-bb97-001dd8b73623]']
                }
            ],
        },
        {
            title: 'Sent Parcels. Get List',
            url: '/api/sentParcels/getList',
            method: 'GET',
            contentType: 'application/json',
            description: 'Список посылок',
            data: [
                {
                    name: 'start',
                    type: 'string',
                    example: '2020.07.15'
                },
                {
                    name: 'end',
                    type: 'string',
                    example: '2022.07.15'
                },
            ],
        },
        {
            title: 'Sent Parcels. Export',
            url: '/api/sentParcels/export',
            method: 'POST',
            contentType: 'application/json',
            description: 'Загрузка посылок в файле Excel (.xlsx)',
            data: [
                {
                    name: 'no name',
                    type: 'array',
                    example: 'Отправить массив с посылками которые были получены на запрос "getList"'
                }
            ],
        },
        {
            title: 'Accounting. Get List',
            url: '/api/accounting/getList',
            method: 'GET',
            contentType: 'application/json',
            description: 'Получение списка транзакций',
            data: [
                {
                    name: 'start',
                    type: 'string',
                    example: '2020.07.15'
                },
                {
                    name: 'end',
                    type: 'string',
                    example: '2022.07.15'
                },
            ],
        },
        {
            title: 'User. Get Info',
            url: '/api/user/getInfo',
            method: 'GET',
            contentType: 'application/json',
            description: 'Получение информации о клиенте(юзере)',
            data: [],
        },
        {
            title: 'User. Get Balance',
            url: '/api/user/getBalance',
            method: 'GET',
            contentType: 'application/json',
            description: 'Получение информации о балансе',
            data: [],
        },
        {
            title: 'User. Update Info',
            url: '/api/user/update',
            method: 'POST',
            contentType: 'application/json',
            description: 'Обновление данных клиента',
            data: [
                {
                    name: 'ic_crd_num',
                    type: 'string',
                    example: '123456789123456789',
                },
                {
                    name: 'name',
                    type: 'string',
                    example: 'Mihail Limar'
                },
                {
                    name: 'phone',
                    type: 'string',
                    example: '+380984856654'
                }
            ],
        },
        {
            title: 'Misc. Check parcel data',
            url: '/api/misc/checkParcelData',
            method: 'POST',
            contentType: 'application/json',
            description: 'Перевірка полів що передаються',
            data: [
                {
                    name: 'parcels_data',
                    type: 'array',
                    example: ['[{...}]'],
                },
                {
                    name: 'id',
                    type: 'string',
                    example: Math.floor(Math.random() * 1000),
                },
                {
                    name: 'lang',
                    type: 'string',
                    example: 'en',
                },
                {
                    name: 'country',
                    type: 'string',
                    example: 'UA',
                },
                {
                    name: 'Consignee_name',
                    type: 'string',
                    example: '""',
                },
                {
                    name: 'Consignees_phone',
                    type: 'string',
                    example: '""',
                },
                {
                    name: 'zip',
                    type: 'string',
                    example: '""',
                },
                {
                    name: 'HS_Code',
                    type: 'numeric',
                    example: '638494535',
                },
            ],
        },
        {
            title: 'Misc. Sent To Warehouse',
            url: '/api/misc/sendToWareHouse',
            method: 'POST',
            contentType: 'application/json',
            description: 'Отправка новых посылок на склад',
            data: [
                {
                    name: 'tracks',
                    type: 'array',
                    example: ['["RO834747343"]'],
                },
                {
                    name: 'parcels',
                    type: 'array',
                    example: JSON.stringify([{uid: 'd1497885-d202-11ec-bb97-001dd8b73623'}])
                },
                {
                    name: 'cnts',
                    type: 'numeric',
                    example: '638494535'
                },
                {
                    name: 'warehouse',
                    type: 'string',
                    example: 'GZH'
                },
            ],
        },
        {
            title: 'Misc. Print Sticker (Label)',
            url: '/api/misc/printStickers',
            method: 'POST',
            contentType: 'application/json',
            description: 'Распечатка лейбл',
            data: [
                {
                    name: 'media',
                    type: 'string',
                    example: 'sheet',
                },
                {
                    name: 'format',
                    type: 'string',
                    example: 'custom',
                },
                {
                    name: 'width',
                    type: 'numeric',
                    example: '104',
                },
                {
                    name: 'height',
                    type: 'numeric',
                    example: '210',
                },
                {
                    name: 'top',
                    type: 'numeric',
                    example: '2',
                },
                {
                    name: 'left',
                    type: 'numeric',
                    example: '2',
                },
                {
                    name: 'right',
                    type: 'numeric',
                    example: '2',
                },
                {
                    name: 'bottom',
                    type: 'numeric',
                    example: '2',
                },
                {
                    name: 'vertical-gap',
                    type: 'numeric',
                    example: '2',
                },
                {
                    name: 'horizontal-gap',
                    type: 'numeric',
                    example: '2',
                },
                {
                    name: 'download',
                    type: 'numeric',
                    example: '1(view) or 2(download)',
                },
                {
                    name: 'uid',
                    type: 'array',
                    example: JSON.stringify(['646c86cd-d202-11ec-bb97-001dd8b73623', '646c86cd-d202-11ec-bb97-001dd8b73623']),
                },
            ],
        },
        {
            title: 'Misc. Get Print Preset',
            url: '/api/misc/getPrintPreset',
            method: 'GET',
            contentType: 'application/json',
            description: '',
            data: [],
        },
        {
            title: 'Misc. Get Country List',
            url: '/api/misc/getCountryList',
            method: 'GET',
            contentType: 'application/json',
            description: 'Список доступных стран',
            data: [],
        },
        {
            title: 'Misc. Get Dangerous Goods',
            url: '/api/misc/getDangerousGoods',
            method: 'GET',
            contentType: 'application/json',
            description: 'Список опасных товаров (battery, power bank, etc.',
            data: [],
        },
        {
            title: 'Misc. Get Manager List',
            url: '/api/misc/getManagerList',
            method: 'GET',
            contentType: 'application/json',
            description: 'Список менеджеров. Список необходим для страницы регистрации для заполнения select',
            data: [],
        },
        {
            title: 'Alipay. Create Payment',
            url: '/api/alipay/createPayment',
            method: 'POST',
            contentType: 'application/json',
            description: 'Создание платежа и получение формы для оплаты',
            data: [
                {
                    name: 'debt',
                    type: 'numeric',
                    example: '10.5'
                }
            ],
        },
        {
            title: 'Alipay. Receive Notify',
            url: '/api/alipay/receiveNotify',
            method: 'POST',
            contentType: 'application/json',
            description: 'Уведомление об оплате. Это webhook для AliPay. Сюда приходит от ALiPay уведомление о результате оплаты.',
            data: [],
        },
        {
            title: 'Admin. Save Danger Worlds',
            url: '/api/admin/saveDangerWorlds',
            method: 'POST',
            contentType: 'application/json',
            description: 'Обновление списка опасных грузов.',
            data: [
                {
                    name: 'dangerWorlds',
                    type: 'array',
                    example: JSON.stringify(["BATTERY", "POWERBANK", "LITHIUM"])
                }
            ],
        },
        {
            title: 'Admin. Save Phone Mask',
            url: '/api/admin/savePhoneMask',
            method: 'POST',
            contentType: 'application/json',
            description: 'Обновление списка телефонных масок.',
            data: [
                {
                    name: 'phoneMask',
                    type: 'array',
                    example: JSON.stringify([{
                        ISO: "CN",
                        default: true,
                        mask: "+86 *** ****-***"
                    }])
                }
            ],
        },
        {
            title: 'Admin. Save Country Details',
            url: '/api/admin/saveCountryList',
            method: 'POST',
            contentType: 'application/json',
            description: 'Обновление списка деталей стран.',
            data: [
                {
                    name: 'countryList',
                    type: 'array',
                    example: JSON.stringify([{
                        country_id: "AT",
                        country_name_en: "AUSTRIA",
                        max_cost: "150",
                        max_weight: "2"
                    }])
                }
            ],
        },
        {
            title: 'Admin. Add New Question Template',
            url: '/api/admin/addQuestionTemplate',
            method: 'POST',
            contentType: 'application/json',
            description: 'Добавление нового шаблона опросника.',
            data: [
                {
                    name: 'template',
                    type: 'object',
                    example: JSON.stringify({
                        "active": false,
                        "require": true,
                        "name": "test10",
                        "questions": [
                            {
                                "en": "",
                                "cn": "",
                                "require": false,
                                "type": 1,
                                "variant": [
                                    {
                                        "en": "",
                                        "cn": ""
                                    }
                                ],
                                "text": ""
                            }
                        ]
                    })
                }
            ],
        },
        {
            title: 'Admin. Edit Question Template',
            url: '/api/admin/updateQuestionTemplate',
            method: 'PUT',
            contentType: 'application/json',
            description: 'Редакирование шаблона опросника.',
            data: [
                {
                    name: 'template',
                    type: 'object',
                    example: JSON.stringify({
                        "id": "63809dc9b68ab",
                        "active": false,
                        "require": true,
                        "name": "test10",
                        "questions": [
                            {
                                "en": "",
                                "cn": "",
                                "require": false,
                                "type": 1,
                                "variant": [
                                    {
                                        "en": "",
                                        "cn": ""
                                    }
                                ],
                                "text": ""
                            }
                        ]
                    })
                }
            ],
        },
        {
            title: 'Admin. Get List Question Template',
            url: '/api/admin/getListQuestionTemplate',
            method: 'GET',
            contentType: 'application/json',
            description: 'Список шаблонов опроса.',
            data: [],
        },
        {
            title: 'Admin. Get Last Active Question Template',
            url: '/api/admin/getLastActiveQuestionTemplate',
            method: 'GET',
            contentType: 'application/json',
            description: 'Шаблонов опроса.',
            data: [],
        },
        {
            title: 'Admin. Make Answers Excel',
            url: '/api/admin/getAnswersExcel',
            method: 'GET',
            contentType: 'application/json',
            description: 'Сгенерировать файл excel с ответами по опросу.',
            data: [
                {
                    name: 'id_question (required)',
                    type: 'string',
                    example: '637ce3e2a8f90'
                },
                {
                    name: 'date_from',
                    type: 'number',
                    example: 1669139627
                },
                {
                    name: 'date_to',
                    type: 'number',
                    example: 1669139627
                }
            ],
        },
        {
            title: 'Admin. Delete Question Template',
            url: '/api/admin/deleteQuestion',
            method: 'POST',
            contentType: 'application/json',
            description: 'Удалить шаблон.',
            data: [
                {
                    name: 'id (required)',
                    type: 'string',
                    example: '637ce3e2a8f90'
                },
            ],
        },
        {
            title: 'Admin. Clear question answers',
            url: '/api/admin/clearCach',
            method: 'POST',
            contentType: 'application/json',
            description: 'Удалить шаблон.',
            data: [
                {
                    name: 'id (required)',
                    type: 'string',
                    example: '637ce3e2a8f90'
                },
            ],
        },
        {
            title: 'Admin. Get Query Statistics',
            url: '/api/admin/getQueryStatistics',
            method: 'POST',
            contentType: 'application/json',
            description: 'Надати статистику по запитам',
            data: [
                {
                    from_time: 'Y-m-d H:i:s',
                    to_time: 'Y-m-d H:i:s',
                    query_uri: '/api/admin/getQueryStatistics'
                },
            ],
        },
        {
            title: 'Admin. Get Query Statistics Avg',
            url: '/api/admin/getQueryStatisticsAvg',
            method: 'POST',
            contentType: 'application/json',
            description: 'Надати статистику середніх значень по запитам',
            data: [
                {
                    from_time: 'Y-m-d H:i:s',
                    to_time: 'Y-m-d H:i:s',
                    query_uri: '/api/admin/getQueryStatistics'
                },
            ],
        },
        {
            title: 'Admin. Get Requests Uri List',
            url: '/api/admin/getRequestsUriList',
            method: 'POST',
            contentType: 'application/json',
            description: 'Надати список запитів які робились, без статистики',
            data: [],
        },
        {
            title: 'Admin. save warning notification emails',
            url: '/api/admin/saveWarningNotificationEmails',
            method: 'POST',
            contentType: 'application/json',
            description: 'Зберегти список пошт на які робити сповіщення про перевищення часу запиту',
            data: [],
        },
        {
            title: 'Admin. get warning notification emails',
            url: '/api/admin/getWarningNotificationEmails',
            method: 'POST',
            contentType: 'application/json',
            description: 'Надати список пошт на які робити сповіщення про перевищення часу запиту',
            data: [],
        },
        {
            title: 'Admin. save warning notification limits',
            url: '/api/admin/saveWarningNotificationLimits',
            method: 'POST',
            contentType: 'application/json',
            description: 'Зберегти список запитів з їх лімітами',
            data: [],
        },
        {
            title: 'Admin. get warning notification limits',
            url: '/api/admin/getWarningNotificationLimits',
            method: 'POST',
            contentType: 'application/json',
            description: 'Надати список запитів з їх лімітами',
            data: [],
        },
    ]

    return (
        <div className={'container h-100 overflow-auto'}>
            {
                list.map((value, key) =>
                    <div key={key}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className="v-card v-sheet theme--light">
                                    <div className="v-card__title ">{value.title}</div>
                                    <div className="v-card__subtitle ">
                                        <strong className="">{value.description}</strong>
                                    </div>
                                    <div className="v-card__text ">
                                        <div className={'v-card__subtitle'}>
                                            <span className="v-chip theme--light v-size--default info">
                                                <span className="v-chip__content">Method - <b>{value.method}</b></span>
                                            </span>
                                            <span className="v-chip theme--light v-size--default info">
                                                <span className="v-chip__content">URL - <b>{value.url}</b></span>
                                            </span>
                                        </div>
                                        <div className="v-card__subtitle ">
                                            <span className="v-chip theme--light v-size--default secondary">
                                                <span className="v-chip__content">Payload</span>
                                            </span>
                                            {
                                                value.data.length
                                                    ?
                                                    <>
                                                        <br/>
                                                        <hr aria-orientation="horizontal" className="v-divider theme--light"/>
                                                        <div className="row">
                                                            <div className="col col-4"><strong>Name</strong></div>
                                                            <div className="col col-4"><strong>Type</strong></div>
                                                            <div className="col col-4"><strong>Example</strong></div>
                                                        </div>
                                                        <hr aria-orientation="horizontal" className="v-divider theme--light"/>
                                                        <br/>
                                                        {
                                                            value.data.map((v, k) =>
                                                                <div key={k} className="row pt-3 pb-3">
                                                                    <div className="col col-4 cursor-hover">
                                                                        <span><pre>{v.name}</pre></span>
                                                                    </div>
                                                                    <div className="col col-4">
                                                                        <span>{v.type}</span>
                                                                    </div>
                                                                    <div className="col col-4">
                                                                        <span><code>{v.example}</code></span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    : <p className="">---Empty---</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                )
            }
        </div>
    );
};

export default Api;
