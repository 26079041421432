import React, { memo, useEffect, useState }          from 'react';
import { useSelector }                               from "react-redux";

import CopyIcon                                      from "./CopyIcon";
import LoadInput                                     from "./LoadInput";
import TableItem                                     from "./tables_elements/TableItem";

const ItemList = ({
                      handleHover,
                      item,
                      name,
                      col,
                      mobileClassIgnore,
                      classNames,
                      classNamesChildren,
                      isTrackNumber,
                      nameColumn,
                      styles,
                      click,
                      attr,
                      copy,
                      afterLoadData,
                      children,
                      classTableItem,
                      _dataField,
                      tooltip,
                      tooltipFor
                  }) => {

    const [dataField, setDataField] = useState(_dataField);

    useEffect(() => {
        if (afterLoadData) {
            if (typeof afterLoadData === "function")
                setDataField(afterLoadData(item))
            else
                setDataField(_dataField)
        } else {
            setDataField(_dataField)
        }
    }, [afterLoadData, item, _dataField])

    const isMobile = useSelector(state => state.global.isMobile)

    const ref = React.createRef();

    const style = {
        fontSize: '10px',
        lineHeight: '20px',
        ...styles
    };

    if (isTrackNumber) {
        style.color = '#0060AE';
        style.fontWeight = 700;
        style.textDecorationLine = 'underline';
    }

    function onClick(event) {
        if (typeof click === 'function') {
            click(event, item, ref)
        }
    }

    return (
        <div ref={ref} className={`d-flex align-items-center ${typeof col === 'number' ? 'my-col-' + col : col} ${!isMobile && !mobileClassIgnore ? 'p-2 py-3' : ''} ${classNames || ''}`}>
            <TableItem tooltip={tooltip ? tooltip : dataField}
                       hover={handleHover}
                       copy={copy}
                       classNameText={classTableItem || ''}
                       tooltipFor={tooltipFor}
            >
                {nameColumn ? <span className={'name-column'}>{nameColumn}</span> : null}
                {
                    children
                        ?
                        children
                        :
                        copy
                            ?
                            <div className={`d-flex ${classNamesChildren ?? ''}`}>
                                <div {...attr} onClick={onClick} style={style}
                                     className={`text-minimise ${!isMobile ? '' : ''}`}>
                                    {/*{item[name] ?? ''}*/}
                                    {dataField}
                                </div>
                                {/*<CopyIcon value={item[name] ?? ''}/>*/}
                                <CopyIcon value={dataField} styles={{height: '20px', marginLeft: '8px'}}/>
                            </div>
                            :
                            <div {...attr} onClick={onClick} style={style}
                                 className={`text-minimise ${!isMobile ? '' : ''} ${classNamesChildren ?? ''}`}>
                                {/*{item[name] ?? ''}*/}
                                {dataField}
                                <LoadInput loaded={dataField === null}/>
                            </div>
                }
            </TableItem>
        </div>
    );
};

export default memo(ItemList);
