
import React, { memo }    from 'react';
import { useTranslation } from "react-i18next";

import SearchInput        from "../../components/shared/SearchInput";
import MyDatePicker       from "../../components/shared/datepicker/MyDatePicker";
import ButtonStyleAction  from "../../components/shared/pages_buttons/ButtonStyleAction";
import ExportButton       from "../../components/shared/pages_buttons/ExportButton";
import FilterListBtn      from "../../components/shared/pages_buttons/FilterListBtn";

const ActionBlock = (props) => {

    const {t} = useTranslation();

    return (
        <>
            <div className={'page-actions d-flex-mobile d-flex-mobile-none justify-content-between'}>
                <div className={'d-flex justify-content-start align-items-center'}>
                    <FilterListBtn page={'accounting'} isHighBtn={true}/>
                    <SearchInput handleSearch={props.handleSearch} className={'mr-3'} classNamesClear={''}/>
                    <MyDatePicker
                        handle={props.handleDatePicker}
                        startEnd
                        className={'d-flex'}
                        stylesFirstDate={{marginRight: '15px'}}
                        classNameFirstDate={'mr-3 flex-nowrap'}
                        classNameSecondDate={'mr-3 flex-nowrap'}
                        DPCClassNames={'flex-shrink-1 minw-100'}
                        textFirst={t('date_from')}
                        textSecond={t('date_to')}
                        colFirst={['auto']}
                        colSecond={['auto']}
                    />
                </div>
                <div className={'d-flex justify-content-end align-items-center'}>
                    <ButtonStyleAction handle={props.openGroupSearch} className={'ml-5'} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
                    <ExportButton
                        handle={props.handleExport}
                        className={'ml-5'}
                        text={t('Detailed_form')}
                    />
                </div>
            </div>
            <div className={'page-actions d-mobile'}>
                <div className={'d-flex justify-content-between align-items-center px-2'}>
                    <SearchInput className={'w-100'} handleSearch={props.handleSearch}/>
                </div>
                <div className={'d-flex justify-content-between align-items-center px-2'}>
                    <MyDatePicker
                        handle={props.handleDatePicker}
                        startEnd
                        className={'d-flex'}
                        colFirst={['12', 'auto']}
                        colSecond={['12', 'auto']}
                        classNameFirstDate={'px-2 pt-2'}
                        classNameSecondDate={'px-2 pt-2'}
                        textFirst={t('date_from')}
                        textSecond={t('date_to')}
                    />
                </div>
            </div>
        </>
    )
};

export default memo(ActionBlock);
