
import React, { memo }          from 'react';
import { useTranslation }       from "react-i18next";

import InfoWithIcon       from "../unidentified_parcels/InfoWithIcon";

const ActionBlockMobile = (props) => {

    const {t} = useTranslation();

    return (
        <div className={'my-d-lg-none d-flex-mobile justify-content-between'}>
            <div className={'d-flex justify-content-start align-items-center mr-3 ws-nowrap'}>
                <InfoWithIcon
                    className={''}
                    dataClass={'promo-codes'}
                    text={t('How_do_I_use_the_coupon_code')}
                    info={`<div class="wb-normal">${t('Using_Your_Promo_Code')+'<br/>'+t('All_countries_hint')}</div>`}
                />
            </div>
        </div>
    )
};

export default memo(ActionBlockMobile);
